<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<h3 class="h3 mb-0">表單範本管理</h3>
			<router-link :to="{ name: 'TemplateBuild' }" class="btn btn-info flex-shrink-0 ms-auto me-3"
				>新增表單範本</router-link
			>
			<router-link :to="{ name: 'TemplateSort' }" class="btn btn-secondary flex-shrink-0">編輯排序</router-link>
		</div>

		<div class="d-flex mb-4">
			<input
				style="width: 300px"
				type="text"
				class="form-control me-3"
				placeholder="標題"
				v-model="searchKeyword"
				@keyup.enter="getList()"
			/>
			<button class="flex-shrink-0 btn btn-primary" @click="getList()">查詢</button>
		</div>

		<table class="table mb-5">
			<thead class="bg-light">
				<tr>
					<th>表單範本名稱</th>
					<th>建立日期</th>
					<th class="text-center">使用次數</th>
					<th class="text-center" style="width: 50px"></th>
					<th class="text-center" style="width: 50px"></th>
					<th class="text-center" style="width: 50px"></th>
					<th class="text-center" style="width: 50px"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item in pageList" :key="item.id">
					<td>{{ item.title }}</td>
					<td>{{ item.created_at }}</td>
					<td class="text-center">{{ item.use_count }}</td>
					<td class="text-center">
						<router-link :to="{ name: 'TemplateBuild', query: { id: item.id } }" class="link-primary"
							>編輯</router-link
						>
					</td>
					<td class="text-center">
						<a
							v-if="item.type == 0 && item.use_count == 0"
							class="link-danger"
							role="button"
							@click="deleteData(item.id)"
							>刪除</a
						>
					</td>
					<td class="text-center">
						<a v-if="item.type == 0" class="link-primary" role="button" @click="copyData(item.id)">複製</a>
					</td>
					<td class="text-center">
						<a
							v-if="item.type == 0"
							class="link-primary"
							role="button"
							@click="changeDataStatus(item.id, item.status)"
						>
							<span v-if="item.status == 0" class="text-secondary">停用</span>
							<span v-else-if="item.status == 1">啟用</span>
						</a>
					</td>
				</tr>
			</tbody>
		</table>

		<Pagination
			v-model="pagination.currentPage"
			:records="pagination.records"
			:per-page="pagination.perPage"
			:options="pagination.options"
			@paginate="getPageList"
		/>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { apiTemplate, apiTemplateCopy, apiTemplateChangeStatus } from "@/assets/js/api.js";
import { textEllipsis } from "@/assets/js/common.js";
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";

export default {
	name: "Template",

	setup() {
		const store = useStore();

		let dataList = [];

		const pageList = ref([]);
		const searchKeyword = ref("");

		const pagination = reactive({
			currentPage: 1,
			records: 0,
			perPage: 20,
			options: {
				theme: "bootstrap4",
				hideCount: true,
			},
		});

		const setPageLoading = (isLoading) => store.dispatch("setPageLoading", isLoading);

		const getList = () => {
			setPageLoading(true);

			apiTemplate({
				method: "get",
				data: {
					text: searchKeyword.value,
				},
			})
				.then((response) => {
					dataList = response.data.data;
					pagination.currentPage = 1;
					pagination.records = response.data.data.length;
					getPageList(1);

					setPageLoading(false);
				})
				.catch(() => {
					setPageLoading(false);
				});
		};

		const getPageList = (currentPage) => {
			let startIndex = (currentPage - 1) * pagination.perPage;
			let endIndex = startIndex + pagination.perPage;

			pageList.value = dataList.slice(startIndex, endIndex);
		};

		const deleteData = (id) => {
			Swal.fire({
				icon: "question",
				text: "確定要刪除嗎?",
				showCancelButton: true,
				confirmButtonText: "確定",
				cancelButtonText: "取消",
			}).then((result) => {
				if (result.isConfirmed) {
					setPageLoading(true);

					apiTemplate({
						method: "delete",
						id,
					})
						.then((response) => {
							setPageLoading(false);
							if (response.data.status == "0") {
								Swal.fire({
									icon: "success",
									text: response.data.message,
								});
								getList();
							} else {
								Swal.fire({
									icon: "warning",
									text: response.data.message,
								});
							}
						})
						.catch(() => {
							setPageLoading(false);
						});
				}
			});
		};

		const copyData = (id) => {
			Swal.fire({
				icon: "question",
				text: "確定要複製嗎?",
				showCancelButton: true,
				confirmButtonText: "確定",
				cancelButtonText: "取消",
			}).then((result) => {
				if (result.isConfirmed) {
					setPageLoading(true);

					apiTemplateCopy({
						id,
					})
						.then((response) => {
							setPageLoading(false);
							if (response.data.status == "0") {
								Swal.fire({
									icon: "success",
									text: response.data.message,
								});
								getList();
							} else {
								Swal.fire({
									icon: "warning",
									text: response.data.message,
								});
							}
						})
						.catch(() => {
							setPageLoading(false);
						});
				}
			});
		};

		const changeDataStatus = (id, status) => {
			const newStatus = status == 0 ? 1 : 0;

			Swal.fire({
				icon: "question",
				text: `確定要${newStatus == 0 ? "停用" : "啟用"}嗎?`,
				showCancelButton: true,
				confirmButtonText: "確定",
				cancelButtonText: "取消",
			}).then((result) => {
				if (result.isConfirmed) {
					setPageLoading(true);

					apiTemplateChangeStatus({
						id,
						status: newStatus,
					})
						.then((response) => {
							setPageLoading(false);
							if (response.data.status == "0") {
								Swal.fire({
									icon: "success",
									text: response.data.message,
								});
								getList();
							} else {
								Swal.fire({
									icon: "warning",
									text: response.data.message,
								});
							}
						})
						.catch(() => {
							setPageLoading(false);
						});
				}
			});
		};

		onMounted(() => {
			getList();
		});

		return {
			searchKeyword,
			pageList,
			pagination,
			getList,
			getPageList,
			deleteData,
			copyData,
			changeDataStatus,
			textEllipsis,
		};
	},
};
</script>
<style lang="scss" scoped></style>
